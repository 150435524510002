import { MutationTree } from "vuex";
import { State } from "./state";
import { IMainTab } from "@/interfaces/IMainTab";
import { addObject, removeObject } from "@/utils/ArrayUtils";
import _ from "lodash";

export function SET_ACTIVE(state: State, active: IMainTab) {
  state.active[state.activeContext] = active;
  sessionStorage.setItem("active-by-context", JSON.stringify(state.active));
}

export function SET_ACTIVE_TABS_BY_CONTEXT(state: State, active: { [key: string]: IMainTab }) {
  state.active = active;
  sessionStorage.setItem("active-by-context", JSON.stringify(state.active));
}

export function SET_ACTIVE_CONTEXT(state: State, context: string) {
  state.activeContext = context;
  sessionStorage.setItem("active-context", state.activeContext);
}

export function ADD_TAB(state: State, tab: IMainTab) {
  addObject(state.tabs[state.activeContext], tab);
  sessionStorage.setItem("tabs", JSON.stringify(state.tabs));
}

export function REMOVE_TAB(state: State, tab: IMainTab) {
  removeObject(state.tabs[state.activeContext], tab);
  sessionStorage.setItem("tabs", JSON.stringify(state.tabs));
}

export function REMOVE_TAB_BY_ID(state: State, id: string) {
  const tab = state.tabs[state.activeContext].find((t: any) => t.id == id);
  if (tab) {
    removeObject(state.tabs[state.activeContext], tab);
    sessionStorage.setItem("tabs", JSON.stringify(state.tabs));
  }
}

export function SET_CUSTOM_LABEL(state: State, customLabel: string | null) {
  state.customLabel = customLabel;
  sessionStorage.setItem("tabs", JSON.stringify(state.tabs));
}

export function COPY_TABS(state: State, tabs: { [key: string]: Array<IMainTab> }) {
  for (const context of ["fsc", "lms"]) {
    // Use for...of to iterate over array
    if (tabs[context]) {
      // Ensure context exists in tabs
      state.tabs[context] = [...tabs[context]]; // Safely spread the array
    } else {
      console.warn(`No tabs found for context: ${context}`);
      state.tabs[context] = []; // Fallback to empty array if no tabs found
    }
  }
  sessionStorage.setItem("tabs", JSON.stringify(state.tabs)); // Save updated tabs in session storage
}

export function PUSH_TAB_HISTORY(state: State, tab: IMainTab) {
  _.remove(state.tabsHistory[state.activeContext], function (historyTab: IMainTab) {
    return historyTab.id == tab.id;
  });
  state.tabsHistory[state.activeContext].push(tab);
  sessionStorage.setItem("tabs-history", JSON.stringify(state.tabsHistory));
}

export function PUSH_TABS_HISTORY(state: State, tabs: { [key: string]: Array<IMainTab> }) {
  state.tabsHistory = tabs;
}

export function POP_TAB_HISTORY(state: State) {
  state.tabsHistory[state.activeContext].pop();
  sessionStorage.setItem("tabs-history", JSON.stringify(state.tabsHistory));
}

export function REMOVE_TAB_HISTORY_BY_ID(state: State, tab: IMainTab) {
  _.remove(state.tabsHistory[state.activeContext], function (tabHistory) {
    return tabHistory.id == tab.id;
  });
  sessionStorage.setItem("tabs-history", JSON.stringify(state.tabsHistory));
}

export default <MutationTree<State>>{
  SET_ACTIVE,
  SET_ACTIVE_TABS_BY_CONTEXT,
  SET_ACTIVE_CONTEXT,
  ADD_TAB,
  REMOVE_TAB,
  REMOVE_TAB_BY_ID,
  SET_CUSTOM_LABEL,
  COPY_TABS,
  PUSH_TAB_HISTORY,
  POP_TAB_HISTORY,
  PUSH_TABS_HISTORY,
  REMOVE_TAB_HISTORY_BY_ID,
};
