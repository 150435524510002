import i18n from "@/i18n";

export default [
  {
    path: "/lms",
    name: "LMSDashboard",
    meta: {
      title: String(i18n.t("sidebar.dashboard")),
      trans: "sidebar.dashboard",
    },
    component: () => import(/* webpackChunkName: "dashboard" */ "@/views/LMSDashboard/LMSDashboard.vue"),
  },
];
