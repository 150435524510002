import { CrudState } from "@/store/Crud/State";

export class State extends CrudState {
  standardTheoryQuestionExplanationIsLoading = false;
  standardTheoryQuestionExplanationError: string | null = null;
  standardTheoryQuestionExplanation: string | null = null;

  standardTheoryQuestionUserLastModifiedExplanationsIsLoading = false;
  standardTheoryQuestionUserLastModifiedExplanationsError: string | null = null;
  standardTheoryQuestionUserLastModifiedExplanations: any;

  standardTheoryQuestionUserLastSearches: Array<any> = [];
}
