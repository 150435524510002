import i18n from "@/i18n";

export default [
  {
    path: "/lms-question-tutor",
    name: "QuestionTutor",
    meta: {
      title: "QuestionTutor",
      trans: "Der Fragentutor",
    },
    component: () => import(/* webpackChunkName: "dashboard" */ "@/views/LMSExamQuestions/QuestionTutor/QuestionTutor.vue"),
  },
];
