import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faEnvelopeOpenDollar,
  faArrowRight,
  faArrowRightFromBracket,
  faBellOn,
  faBox,
  faBoxArchive,
  faBuilding,
  faCalendarDays,
  faCheck,
  faChevronDown,
  faChevronRight,
  faClock,
  faClockRotateLeft,
  faCoin,
  faCopy,
  faEnvelope,
  faExclamation,
  faEye,
  faFile,
  faFileArrowUp,
  faFileArrowDown,
  faFileCsv,
  faFilePdf,
  faFileImage,
  faFilter,
  faEllipsisVertical,
  faGlasses,
  faHouse,
  faIdCard,
  faLayerPlus,
  faLinkSimple,
  faLocationDot,
  faMagnifyingGlass,
  faMobileScreenButton,
  faPause,
  faPen,
  faPencil,
  faPenField,
  faPhone,
  faPlay,
  faPlus,
  faPowerOff,
  faPrint,
  faReply,
  faReplyAll,
  faReceipt,
  faRotate,
  faShare,
  faScaleUnbalanced,
  faSignature,
  faSplit,
  faSquareInfo,
  faStopwatch,
  faTrash,
  faUpload,
  faUser,
  faUserCheck,
  faVial,
  faWandMagicSparkles,
  faXmark,
  faUserTag,
  faUserPlus,
  faQrcode,
  faDownLeftAndUpRightToCenter,
  faUpRightAndDownLeftFromCenter,
  faInfo,
  faTrashCan,
  faCartArrowDown,
  faCartShopping,
  faPaperclip,
  faCircleNotch,
  faAngleLeft,
  faAngleRight,
  faCircleCheck,
  faSpinner,
  faFlag,
  faTimes,
  faCalendarDay,
  faTrashAlt,
  faMapMarkerAlt,
  faUserLock,
  faMobileAlt,
  faExternalLinkAlt,
  faAngleDown,
  faKey,
  faWifi,
  fa1,
  faAddressCard,
  faBoxOpen,
  faCalculator,
  faCalculatorSimple,
  faCarBuilding,
  faChartLineUp,
  faChartPie,
  faChartScatterBubble,
  faDisplay,
  faEnvelopeDot,
  faEnvelopes,
  faFileExcel,
  faFileSpreadsheet,
  faFolderGear,
  faGarageOpen,
  faGears,
  faGraduationCap,
  faInfinity,
  faListCheck,
  faLocationPlus,
  faScreenUsers,
  faSmilePlus,
  faSparkles,
  faSteeringWheel,
  faTags,
  faUserNurse,
  faUsersGear,
  faWallet,
  faMoon,
  faAddressBook,
  faCoins,
  faFileInvoiceDollar,
  faFileLines,
  faMessageLines,
  faCamera,
  faFileCheck,
  faFax,
  faUserTie,
  faInfoSquare,
  faFilePlus,
  faFileXmark,
  faFileSignature,
  faCar,
  faNote,
  faBringForward,
  faBan,
  faGear,
  faMobile,
  faFileWord,
  faArrowDownToSquare,
  faLink,
  faAngleDoubleRight,
  faAngleDoubleLeft,
  faMessages,
  faCalendar,
  faWavePulse,
  faX,
  faLanguage,
  faGlobe,
  faCalendarCheck,
  faClipboardListCheck,
  faRotateRight,
  faQuestionCircle,
  faExclamationCircle,
  faSignOut,
  faBook,
  faLightbulbOn,
  faChalkboardUser,
  faBallotCheck,
} from "@fortawesome/pro-thin-svg-icons";

library.add(
  faPlus,
  faPen,
  faTrash,
  faFileArrowUp,
  faFileArrowDown,
  faFilter,
  faMagnifyingGlass,
  faFileCsv,
  faPrint,
  faXmark,
  faCopy,
  faUser,
  faPlay,
  faPause,
  faCheck,
  faEnvelope,
  faEllipsisVertical,
  faSquareInfo,
  faCalendarDays,
  faClock,
  faLocationDot,
  faStopwatch,
  faUserCheck,
  faSplit,
  faExclamation,
  faUpload,
  faIdCard,
  faClockRotateLeft,
  faBoxArchive,
  faGlasses,
  faPhone,
  faHouse,
  faMobileScreenButton,
  faBuilding,
  faPencil,
  faBox,
  faFile,
  faCoin,
  faScaleUnbalanced,
  faPowerOff,
  faBellOn,
  faRotate,
  faArrowRightFromBracket,
  faFilePdf,
  faFileImage,
  faPenField,
  faLayerPlus,
  faLinkSimple,
  faArrowRightFromBracket,
  faEye,
  faSignature,
  faChevronRight,
  faChevronDown,
  faReceipt,
  faWandMagicSparkles,
  faArrowRight,
  faVial,
  faUserTag,
  faUserPlus,
  faQrcode,
  faDownLeftAndUpRightToCenter,
  faUpRightAndDownLeftFromCenter,
  faInfo,
  faTrashCan,
  faCartArrowDown,
  faCartShopping,
  faPaperclip,
  faCircleNotch,
  faAngleLeft,
  faAngleRight,
  faCircleCheck,
  faSpinner,
  faFlag,
  faTimes,
  faCalendarDay,
  faTrashAlt,
  faMapMarkerAlt,
  faUserLock,
  faMobileAlt,
  faExternalLinkAlt,
  faAngleDown,
  faKey,
  faWifi,
  fa1,
  faAddressCard,
  faBoxOpen,
  faCalculator,
  faCalculatorSimple,
  faCarBuilding,
  faChartLineUp,
  faChartPie,
  faChartScatterBubble,
  faDisplay,
  faEnvelopeDot,
  faEnvelopes,
  faFileExcel,
  faFileSpreadsheet,
  faFolderGear,
  faGarageOpen,
  faGears,
  faGraduationCap,
  faInfinity,
  faListCheck,
  faLocationPlus,
  faScreenUsers,
  faSmilePlus,
  faSparkles,
  faSteeringWheel,
  faTags,
  faUserNurse,
  faUsersGear,
  faWallet,
  faMoon,
  faAddressBook,
  faCoins,
  faFileInvoiceDollar,
  faFileLines,
  faMessageLines,
  faCamera,
  faFileCheck,
  faFax,
  faEnvelopeOpenDollar,
  faUserTie,
  faInfoSquare,
  faFilePlus,
  faFileXmark,
  faFileSignature,
  faCar,
  faNote,
  faBringForward,
  faBan,
  faMobile,
  faReply,
  faReplyAll,
  faShare,
  faFileWord,
  faGear,
  faArrowDownToSquare,
  faLink,
  faAngleDoubleRight,
  faAngleDoubleLeft,
  faMessages,
  faCalendar,
  faWavePulse,
  faX,
  faLanguage,
  faGlobe,
  faCalendarCheck,
  faClipboardListCheck,
  faRotateRight,
  faQuestionCircle,
  faExclamationCircle,
  faSignOut,
  faBook,
  faLightbulbOn,
  faChalkboardUser,
  faBallotCheck
);
